<template>
  <div
    class="md:min-w-[186px] relative md:w-full"
    v-click-outside="closeDropDownFilter"
  >
    <div
      class="cursor-pointer md:p-[5px] md:flex md:justify-center md:items-center md:font-[500] md:tracking-[4%] md:w-full md:rounded-tl-[6px] md:rounded-tr-[6px] md:rounded-br-[6px] md:rounded-bl-[6px] md:uppercase md:min-h-[32px] md:bg-blue-500/[0.2] md:text-[12px] md:leading-[14px] md:text-blue-500"
      :class="{ 'md:rounded-br-[0] md:rounded-bl-[0]': visible }"
      @click="toggleVisible"
    >
      <div
        class="inline-flex items-center toggle-btn-text pr-[20px]"
        :class="{ 'toggle-btn-text-active': visible }"
      >
        <div class="mr-[5px]">
          <img src="@/assets/icons/settings-dashboard.svg">
        </div>
        <div class="hidden md:block">wallet settings</div>
      </div>
    </div>
    <div
      class="z-[999] dropdown-filter min-w-[186px] md:w-full absolute top-[32px] right-0 md:right-auto md:left-0 rounded-bl-[6px] rounded-br-[6px] shadow-component-wrapper-root bg-blue-800 pt-[10px] pb-[12px] pl-[11px] pr-[11px]"
      :class="{ 'open': visible }"
    >
      <div
        class="cursor-pointer flex items-center text-[12px] leading-[14px] font-normal text-white hover:text-blue-500 transition mb-[10px] last:mb-0"
        v-for="filter in filters"
        :key="filter.id"
        @click="onChooseWalletsItem(filter.name)"
      >
        <img
          :src="getImg(filter.iconUrl)"
          class="mr-[5px]"
        >
        {{ filter.title }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WalletFilter',
  data() {
    return {
      visible: false,
      filters: [
        {
          name: 'ShowAll',
          title: 'Show all',
          iconUrl: 'iconShow.svg',
          id: 1
        },
        {
          name: 'ShowOnlyFiat',
          title: 'Show only Fiat',
          iconUrl: 'iconShow.svg',
          id: 2
        },
        {
          name: 'ShowOnlyCrypto',
          title: 'Show only Crypto',
          iconUrl: 'iconShow.svg',
          id: 3
        },
        {
          name: 'HideCurrencyZeroBalance',
          title: 'Hide zero currency balance',
          iconUrl: 'iconHide.svg',
          id: 4
        },
      ],
    };
  },
  methods: {
    getImg(url) {
      try {
        return require(`@/assets/icons/${ url }`);
      } catch (error) {
        return '';
      }
    },
    onChooseWalletsItem( nameFilterWallets ) {
      this.$emit('onChooseWalletItemsFilter', nameFilterWallets)
      this.visible = false
    },
    toggleVisible() {
      this.visible = !this.visible
    },
    closeDropDownFilter() {
      this.visible = false
    }
  },
};
</script>
<style scoped lang="scss">
.toggle-btn-text {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #2af3f3 transparent transparent transparent;
  }

  &.toggle-btn-text-active {
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
.dropdown-filter {
  opacity: 0;
  visibility: hidden;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.ProvidersTable_Filter {
  position: relative;
  text-align: left;

  &Button {
    width: 100%;
  }
  &Dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 260px;
    background: #2D4D70;
    box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.55);
    border-radius: 4px;
    margin: 12px 0 0;
    opacity: 0;
    visibility: hidden;

    &:before {
      content: '';
      width: 100%;
      height: 12px;
      top: 100%;
      left: 0;
      position: absolute;
    }

    .ProvidersTable_Filter--open & {
      opacity: 1;
      visibility: visible;
    }

    &Head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #A6AEBF;
      height: 46px;
      padding: 8px 10px;
    }

    &Title{
      font-size: 12px;
      line-height: 14px;
      color: #fff;
    }

    &Done{
      display: inline-flex;
      justify-content:center;
      align-items:center;
      text-align: center;
      padding: 3px 6px;
      background: rgba(#2AF3F3, .1);
      border-radius: 1.6px;
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
      color: #2AF3F3;
      cursor: pointer;
    }

    &List{
      &Item{
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid rgba(#2AF3F3, .21);

        &:last-child{
          border-bottom-color: transparent;
        }

        &--open{
          display: block;
          cursor: default;
        }

        &Title{
          font-size: 12px;
          line-height: 14px;
          color: #fff;
          text-transform: uppercase;

          .ProvidersTable_FilterDropdownListItem--open & {
            color: #2AF3F3;
            margin: 0 0 4px;
          }
        }

        &Arrow{
          width: 10px;

          svg {
            transform: rotate(90deg);
            width: 100%;
            height: 100%;
            display: block;
          }

          .ProvidersTable_FilterDropdownListItem--open & {
            display: none;
          }
        }

        &Select{
          display: none;

          .ProvidersTable_FilterDropdownListItem--open & {
            display: block;
          }
        }
      }
    }
  }
}
</style>