<!-- Wallet/index.vue should be parent page, Balance/Balance.vue should be main child page, AppSection should cointain <router-view>  -->
<template>
  <AppSection title="Wallet" additionalsStyles="flex items-center justify-between">
    <div slot="tollbar">
      <WalletFilter
        @onChooseWalletItemsFilter="currentNameWalletItem = $event"
      ></WalletFilter>
    </div>
    <div slot="content">
      <div class="pb-[10px] border-b-[1px] border-[#2af3f3]/[0.1] mb-[20px] mb:pb-[20px] mb:mb-[30px]">
        <WalletTotalBalance />
      </div>
      <div>
        <div class="mb-[30px] last:mb-0" v-if="currentNameWalletItem === 'ShowOnlyFiat' || currentNameWalletItem === 'ShowAll' || currentNameWalletItem === 'HideCurrencyZeroBalance'">
          <h2 class="mb-[14px] p-0 font-medium text-[14px] leading-[16px] uppercase text-[#0F1E30] dark:text-white text-left md:text-[18px] md:leading-[21px] md:mb-[20px] 2xl:text-[21px] 2xl:leading-[1.1]">FIAT BALANCE</h2>
          <GridTable
            v-if="getterFiatBalance.length"
            :balance="getFilterWalletsItemFiat"
          />
        </div>
        <div class="mb-[30px] last:mb-0" v-if="currentNameWalletItem === 'ShowOnlyCrypto' || currentNameWalletItem === 'ShowAll' || currentNameWalletItem === 'HideCurrencyZeroBalance'">
          <h2 class="mb-[14px] p-0 font-medium text-[14px] leading-[16px] uppercase text-[#0F1E30] dark:text-white text-left md:text-[18px] md:leading-[21px] md:mb-[20px] 2xl:text-[21px] 2xl:leading-[1.1]">CRYPTO BALANCE</h2>
          <GridTable
            v-if="getterCryptoBalance.length"
            :balance="getFilterWalletsItemCrypto"
          />
        </div>
      </div>
    </div>
  </AppSection>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AppSection from '@/components/_common/AppSection/AppSection';
import WalletTotalBalance from '@/components/_common/Wallet/WalletTotalBalance/WalletTotalBalance';
import GridTable from './GridTable/GridTable';
import WalletFilter from "@/components/_common/WalletFilter/WalletFilter";

export default {
  components: {
    AppSection,
    GridTable,
    WalletTotalBalance,
    WalletFilter
  },
  data(){
    return {
      currentNameWalletItem: 'ShowAll',
    }
  },
  computed: {
    ...mapState('exchange', ['currencies']),
    ...mapGetters('balance', ['getterFiatBalance', 'getterCryptoBalance']),
    getFilterWalletsItemFiat() {
      return this.getterFiatBalance.filter(currency => {
        if (this.currentNameWalletItem === "HideCurrencyZeroBalance") {
          return currency[currency.currency].total - currency[currency.currency].reserved > 0
        } else {
          return true
        }
      }) || []
    },

    getFilterWalletsItemCrypto() {
      return this.getterCryptoBalance.filter(currency => {
        if (this.currentNameWalletItem === "HideCurrencyZeroBalance") {
          return currency[currency.currency].total - currency[currency.currency].reserved > 0
        } else {
          return true
        }
      }) || []
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
