import store from '@/store';
// TODO: Get from BE list of Networks
const networks = ['ERC20', 'TRC20', 'BEP20'];
export const allWithdrawalMethodsDisabled = currency => {
  const {
    state: {
      user: { userWithdrawalRules },
    },
  } = store;
  if (userWithdrawalRules && currency) {
    const methodsByCurrency = userWithdrawalRules[currency];
    if (!methodsByCurrency) return true;
    const allMethodsDisabled = Object.keys(methodsByCurrency)
      .map(methodName => {
        let method;
        if (methodsByCurrency[methodName] && methodsByCurrency[methodName]) {
          if (Object.keys(methodsByCurrency[methodName]).some(n => networks.includes(n))) {
            method = Object.keys(methodsByCurrency[methodName])
              .reduce((a, i) => {
                return [...a, methodsByCurrency[methodName][i]];
              }, [])
              .every(i => i?.disable_description === 'DISABLED' || i?.disable_description === 'RESET_PASSWORD_LOCK_IN_PROGRESS')
              ? { disable_description: 'DISABLED' }
              : { disable_description: 'NOT DISABLED' };
          } else {
            method = methodsByCurrency[methodName];
          }
        }
        const isMethodAvailable = method && method.disable_description;
        return isMethodAvailable;
      })
      .every(item => item === 'DISABLED' || item === 'RESET_PASSWORD_LOCK_IN_PROGRESS');
    return allMethodsDisabled;
  }
  return true;
};

export const allActiveWithdrawalMethods = currency => {
  const {
    state: {
      user: { userWithdrawalRules },
    },
  } = store;
  if (userWithdrawalRules && currency) {
    const methodsByCurrency = userWithdrawalRules[currency];
    if (!methodsByCurrency) return {};
    // console.log(methodsByCurrency)
    const availableMethodsByCurrency = Object.keys(methodsByCurrency).reduce((acc, curr) => {
      const method = methodsByCurrency[curr];
      // console.log(method)
      // if (method?.disable_description !== 'DISABLED') {
      // const isMethodHaveNetwork = Object.keys(method).some(n => networks.includes(n));
      // if (isMethodHaveNetwork) {

      // }
      // if (method?.disable_description !== 'DISABLED') {
      //   acc[curr] = method;
      // }
      // console.log()
      // if (method.is_withdrawal_enabled || Object.entries(method).some(([key, value]) =>  value.is_withdrawal_enabled )) {
      if (method?.disable_description !== 'DISABLED' || Object.entries(method).some(([key, value]) =>  value.is_withdrawal_enabled )) {
        acc[curr] = method;
      }
      return acc;
    }, {});
    // console.log(availableMethodsByCurrency)
    // TODO: need fix, this method Breinrock must to be response from BE
    if (availableMethodsByCurrency.CASHBOX) {
      if (['EUR', 'USD'].some(el=>el == currency)) {
        availableMethodsByCurrency.CASHBOX_BREINROCK = availableMethodsByCurrency.CASHBOX
      }
      delete availableMethodsByCurrency.CASHBOX;
    } 
    // console.log(currency, availableMethodsByCurrency)
    return availableMethodsByCurrency;
  }
  return {};
};
