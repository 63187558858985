<template>
  <div class="text-[18px] 2xl:text-[21px] leading-[21px] 2xl:leading-[1.1] text-white text-left font-medium">
    <div class="relative inline-block text-blue-700 dark:text-white flex items-center justify-start">
      <div class="mr-4">Balance:</div>
      <span class="text-blue-500">{{ computedTotalBalance }}</span>
      <Select
        class="select-wallet-balance"
        :items="currenciesToConvert"
        :value="totalAmountCurrency"
        name="currency"
        @change="updateSelectCurrency(arguments[0], 'currency')"
      />
    </div>
  </div>
</template>

<script>
import { getTotalBalanceByCurrency } from '@/components/_common/Wallet/_helpers/userBalances';
import Select from '@/components/_common/FormElements/Select/Select';

export default {
  components: {
    Select,
  },
  data: () => ({
    currenciesToConvert: ['BTC', 'USDT', 'EUR'],
    totalAmountCurrency: 'EUR',
  }),
  computed: {
    computedTotalBalance() {
      return getTotalBalanceByCurrency(this.totalAmountCurrency);
    },
  },
  methods: {
    updateSelectCurrency({ value }) {
      this.totalAmountCurrency = value;
    },
  },
};
</script>
<style lang="scss">
  .select-wrapper.select-wrapper--inputSelect {
    right: 0;
    top: 50%;
    transform: translate(95%, -51%);
    bottom: auto;
  }
</style>