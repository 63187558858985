<template>
  <div class="table w-full text-left">
    <div class="table-row h-[50px] align-middle md:h-[60px] h-auto">
      <div class="table-cell align-middle py-[6px] pr-[12px] text-[10px] leading-[12px] uppercase border-b-[1px] border-dashed border-blue-500/[0.21] md:text-[12px] md:leading-[14px] xl:text-[16px] xl:leading-[18px] md:w-[30%] text-[#0F1E30] dark:text-white">Currency</div>
      <div class="table-cell align-middle py-[6px] pr-[12px] text-[10px] leading-[12px] uppercase border-b-[1px] border-dashed border-blue-500/[0.21] md:text-[12px] md:leading-[14px] xl:text-[16px] xl:leading-[18px] md:w-[18%] text-[#0F1E30] dark:text-white">amount</div>
      <div class="table-cell align-middle py-[6px] pr-[12px] text-[10px] leading-[12px] uppercase border-b-[1px] border-dashed border-blue-500/[0.21] md:text-[12px] md:leading-[14px] xl:text-[16px] xl:leading-[18px] md:w-[18%] text-[#0F1E30] dark:text-white">available</div>
      <div class="table-cell align-middle py-[6px] pr-[12px] text-[10px] leading-[12px] uppercase border-b-[1px] border-dashed border-blue-500/[0.21] md:text-[12px] md:leading-[14px] xl:text-[16px] xl:leading-[18px] md:w-[18%] text-[#0F1E30] dark:text-white">reserved</div>
      <div class="table-cell align-middle py-[6px] pr-[12px] text-[10px] leading-[12px] uppercase border-b-[1px] border-dashed border-blue-500/[0.21] md:text-[12px] md:leading-[14px] xl:text-[16px] xl:leading-[18px] last:text-right last:py-[6px] text-[#0F1E30] dark:text-white"><span class="md:w-[174px] md:inline-block md:text-center">Actions</span></div>
    </div>
    <div class="table-row h-[50px] align-middle md:h-[60px]"
         v-for="item in modifiedBalance"
         :key="item.currency">
      <div class="table-cell align-middle py-[6px] pr-[12px] text-[10px] leading-[12px] border-b-[1px] border-dashed border-blue-500/[0.21] md:text-[12px] md:leading-[14px] md:w-[30%]">
        <div class="flex flex-start items-center">
          <SquareCoinIcon :currency="item.currency"/>
          <span class="hidden ml-[6px] md:block text-[14px] leading-[14px] md:text-[16px] md:leading-[18px] text-[#0F1E30] dark:text-white">{{ currencyFullName(item.currency) }}</span>
          <span class="hidden ml-[6px] md:block text-[14px] leading-[14px] md:text-[16px] md:leading-[18px] text-[#0F1E30] dark:text-[#9395AB]">({{ item.currency }})</span>
        </div>
      </div>
      <div class="table-cell align-middle py-[6px] pr-[12px] text-[10px] leading-[12px] uppercase border-b-[1px] border-dashed border-blue-500/[0.21] md:text-[12px] md:leading-[14px] xl:text-[16px] xl:leading-[18px] md:w-[18%] text-[#0F1E30] dark:text-white">{{ item.total }} {{ item.currency }}</div>
      <div class="table-cell align-middle py-[6px] pr-[12px] text-[10px] leading-[12px] uppercase border-b-[1px] border-dashed border-blue-500/[0.21] md:text-[12px] md:leading-[14px] xl:text-[16px] xl:leading-[18px] md:w-[18%] text-[#0F1E30] dark:text-white">{{ item.availableBalance }} {{ item.currency }}</div>
      <div class="table-cell align-middle py-[6px] pr-[12px] text-[10px] leading-[12px] uppercase border-b-[1px] border-dashed border-blue-500/[0.21] md:text-[12px] md:leading-[14px] xl:text-[16px] xl:leading-[18px] md:w-[18%] text-[#0F1E30] dark:text-white">{{ item.reserved }} {{ item.currency }}</div>
      <div class="table-cell align-middle py-[6px] pr-[12px] text-[10px] leading-[12px] uppercase border-b-[1px] border-dashed border-blue-500/[0.21] md:text-[12px] md:leading-[14px] xl:text-[16px] xl:leading-[18px] last:text-right last:py-[6px] text-[#0F1E30] dark:text-white">
        <div class="flex justify-end">
          <Button
            text="-"
            class="btn btn-small btn-default-small !min-w-[16px] !leading-[16px] !min-h-[16px] mr-[10px] md:!hidden"
            :disabled="item._withdrawalDisabled"
            @click="handleClickWithdraw(item.currency)"
            defaultBtn
          />
          <Button
            text="+"
            class="btn btn-small btn-default-small !min-w-[16px] !leading-[16px] !min-h-[16px] md:!hidden"
            :disabled="item._withdrawalDisabled"
            @click="handleClickWithdraw(item.currency)"
            defaultBtn
          />
          <Button
            text="withdraw"
            class="btn btn-small btn-default-small min-w-[100px] mr-[10px] !hidden md:!block"
            :disabled="item._withdrawalDisabled"
            @click="handleClickWithdraw(item.currency)"
            defaultBtn
          />
          <Button
            text="deposit"
            class="btn btn-small btn-bright btn-bright-small min-w-[100px] !hidden md:!block"
            :disabled="item._depositDisabled"
            @click="handleClickDeposit(item.currency)"
            brightBtn
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allDepositMethodsDisabled } from '@/views/Application/Account/Exchange/Wallet/_helpers/depositRules'
import { allWithdrawalMethodsDisabled } from '@/views/Application/Account/Exchange/Wallet/_helpers/withdrawalRules'
import SquareCoinIcon from '@/components/_common/SquareCoinIcon/SquareCoinIcon'
import Button from '@/components/_common/FormElements/Button/Button'
import { roundingCurrency, currencyFullName } from "@/utils/helpers"

export default {
  components: {
    SquareCoinIcon,
    Button,
  },
  props: {
    balance: {
      type: Array,
      required: true,
    },
  },
  computed: {
    modifiedBalance() {
      return this.balance.map(wallet => {
        const { currency, ...restWallet } = wallet
        const { total, reserved } = restWallet[ currency ]
        const totalInAnotherCurrency = (restWallet[ 'USD' ] && restWallet[ 'USD' ].total) || 0
        const anotherCurrency = 'USD';
        const availableBalance = roundingCurrency({ currency, currencyAmount: total - reserved })
        return {
          currency,
          total: roundingCurrency({ currency, currencyAmount: total }),
          reserved: roundingCurrency({ currency, currencyAmount: reserved }),
          availableBalance,
          totalInAnotherCurrency: roundingCurrency({
            currency: anotherCurrency,
            currencyAmount: totalInAnotherCurrency
          }),
          anotherCurrency,
          _depositDisabled: allDepositMethodsDisabled(currency),
          _withdrawalDisabled: allWithdrawalMethodsDisabled(currency),
        }
      }).filter(item=>item.currency !== "RUB" && item.currency !== "KZT" && item.currency !== "UZS" && item.currency !== "CAD")
    },
  },
  methods: {
    handleClickWithdraw(currency) {
      this.$router.push(`/exchange/wallet/withdraw/${ currency }`)
    },
    handleClickDeposit(currency) {
      this.$router.push(`/exchange/wallet/deposit/${ currency }`)
    },
    currencyFullName,
  },
  mounted() {
    console.log(this.modifiedBalance[0].currency, 'currencyFullName');
  }
};
</script>

<style lang="scss">
@import './GridTable.scss';
</style>
